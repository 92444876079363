import fr from 'simple-keyboard-layouts/build/layouts/french';
import de from 'simple-keyboard-layouts/build/layouts/german';
import en from 'simple-keyboard-layouts/build/layouts/english';

export default {
  data: function() {
    return {
      // eslint-disable-next-line no-unused-labels
      keyboardEpoch: 0
    };
  },
  watch: {
    '$i18n.locale': function() {
      this.keyboardEpoch++;
    }
  },
  computed: {
    layout() {
      switch (this.$i18n.locale) {
        case 'fr':
          console.log('layout: fr');
          return fr;
        case 'de':
          console.log('layout: de');
          return de;
        default:
          console.log('layout: en');
          return en;
      }
    }
  }
};
